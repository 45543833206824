
import { Component, Vue } from 'vue-property-decorator';
import { URI_AUTHORIZE } from '@/api/eob/endpoints';
import { CookieName } from '@/core/cookies';
import { RouteName } from '@/router/models';
import { AuthorizationResponse } from './types';
import { USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';
import { SET_IS_USER_LOGGED, SET_USER_HASH, SET_USER_TOKEN } from '@/store/auth-store/mutations';

const AuthStore = namespace('AuthStore');
const AppStore = namespace('AppStore');

@Component({
  name: 'Authorization'
})
export default class Authorization extends Vue {
  async created (): Promise <void> {
    await this.fetchUserData();
    await this.$router.push({ name: RouteName.HOME });
  }

  async fetchUserData (): Promise<void> {
    try {
      const authorizationResponse: AuthorizationResponse = (await this.$api.post<AuthorizationResponse>(URI_AUTHORIZE, this.$route.query)).data;
      this.handleSuccessResponse(authorizationResponse);
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  @AuthStore.Mutation(SET_USER_TOKEN) setUserToken: any;
  @AuthStore.Mutation(SET_USER_HASH) setUserHash: any;
  @AuthStore.Mutation(SET_IS_USER_LOGGED) setIsUserLogged: any;
  @AppStore.Action(USER_NOTIFY) notify: any;

  handleSuccessResponse (authorizationResponse: AuthorizationResponse) {
    this.setCookies(authorizationResponse);
    this.setAuthData(authorizationResponse);
    window.location.href = sessionStorage.getItem(CookieName.LOGIN_RETURN_PATH) || '/';
  }

  handleErrorResponse (e: XMLHttpRequest): void {
    this.notify({ msg: e, type: 'error', permanent: true });
  }

  setCookies (authorizationResponse: AuthorizationResponse): void {
    this.$cookies.set(CookieName.USER_TOKEN, 'Bearer ' + authorizationResponse.token);
    this.$cookies.set(CookieName.USER_HASH, authorizationResponse.hash);
    sessionStorage.setItem(CookieName.LOGIN_STATUS, authorizationResponse.status.toString());
  }

  setAuthData (authorizationResponse: AuthorizationResponse): void {
    this.setIsUserLogged(true);
    this.setUserHash(authorizationResponse.hash);
    this.setUserToken(authorizationResponse.token);
  }
}
